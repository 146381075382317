.myshowcase.is-loading {
  visibility: hidden; }

.myshowcase__item {
  margin-bottom: 30px; }

@media screen and (max-width: 400px) {
  .myshowcase {
    margin: 0 15px; } }

@media screen and (min-width: 401px) {
  .myshowcase {
    margin-right: 15px; }

  .myshowcase__item {
    box-sizing: border-box;
    width: (100% / 2);
    float: left;
    padding-left: 15px; } }

@media screen and (min-width: 1400px) {
  .myshowcase__item {
    width: (100% / 3); } }

@media screen and (min-width: 900px) {
  .myshowcase {
    padding: 40px 0 10px;
    margin-right: 40px; } }

@media screen and (min-width: 1100px) {
  .myshowcase__item {
    padding-left: 40px; } }
