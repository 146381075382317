.mysidebar__title {
  margin-bottom: 0;

  a {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    color: $dark-body-color;

    &:hover {
      border-bottom: dotted 1px #9055a2; } } }

.mysidebar__intro {
  color: $light-body-color;
  font-size: 14px;
  margin-bottom: 20px;
  top: 0;
  left: 0;
  position: sticky;


  p {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0; } } }

.mysidebar__menu {
  margin-bottom: 20px;

  li {
    a {
      text-decoration: none;
      display: block;
      padding-left: 20px;
      color: $base-body-color;
      position: relative;



      &:hover {
        color: $active-color; } }

    &.is-active a {
      font-weight: bold;
      color: $active-color; } } }

.mysidebar__social {
  margin-bottom: 20px; }

.mysidebar__copyright {
  color: $light-body-color;
  font-size: 13px; }
