#hard {
    color: #f74545
}

#intermediate {
    color: #f76b00
}

#easy {
    color: #008000;
}

#default {
    color: #4981E4;
}