.mobile-header {
  display: flex;
  padding: 20px 0;
  margin: 0 15px; }

.mobile-header__menu {
  button {
    border: 0;
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('https://cdn.rawgit.com/encharm/Font-Awesome-SVG-PNG/master/black/svg/bars.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain; } }

.mobile-header__logo {
  flex: 1;
  text-align: right;

  a {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    color: $dark-body-color; } }
