@import "node_modules/bootstrap/scss/bootstrap";
@import "./sass-styles/variables/colors";
@import "./sass-styles/reset";
@import "./sass-styles/blocks/card";
@import "./sass-styles/blocks/container";
@import "./sass-styles/blocks/mobile-header";
@import "./sass-styles/blocks/sheet";
@import "./sass-styles/blocks/showcase";
@import "./sass-styles/blocks/sidebar";
@import "./sass-styles/blocks/slider";
@import "./sass-styles/blocks/social";
@import "../node_modules/slick-carousel/slick/slick.css";
@import "../node_modules/slick-carousel/slick/slick-theme.css";





