.mysheet {
  padding-top: 4px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white; }

.mysheet__inner {
  max-width: 600px; }

.mysheet__title {
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: bold;
  color: $dark-body-color;
  line-height: 1; }

.mysheet__lead {
  font-size: 16px;
  margin-bottom: 30px; }

.mysheet__gallery,
.mysheet__slider {
  margin-bottom: 30px; }

.mysheet__body {
  color: $light-body-color;
  font-size: 14px;
  margin-bottom: 30px;

  h1, h2, h3, h4, h5, h6, p, blockquote, pre, ul, ol {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0; } }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin-top: 40px;
    color: $dark-body-color; }

  h1 {
    font-size: 3em; }

  h2 {
    font-size: 2em; }

  h3 {
    font-size: 1.5em; }

  li {
    margin-left: 20px;
    margin-bottom: 5px; }

  ol li {
    list-style-type: decimal; }

  ul li {
    list-style-type: disc; }

  blockquote {
    border-left: 4px solid $active-color;
    font-size: 1.1em;
    padding: 20px; } }

@media screen and (min-width: 900px) {
  .mysheet {
    padding-top: 4px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px; }

  .mysheet__title {
    font-size: 48px; }

  .mysheet__lead {
    font-size: 20px; }

  .mysheet__body {
    font-size: 16px;

    blockquote {
      padding-left: 40px; } } }
