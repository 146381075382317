@media screen and (max-width: 899px) {
  .mycontainer__sidebar {
    box-sizing: border-box;
    width: 200px;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    transform: translate3d(-200px, 0, 0);
    transition: transform 0.2s ease-in-out;
    padding: 20px;
    background-color: $light-bg-color;

    .mycontainer.is-open & {
      transform: translate3d(0px, 0, 0); } }

  .mycontainer__body {
    transform: translate3d(0px, 0, 0);
    transition: transform 0.2s ease-in-out;

    .mycontainer.is-open & {
      transform: translate3d(200px, 0, 0); } }

  .header-container {
    padding: 20px;
    padding-left: 40px; } }

@media screen and (min-width: 900px) {
  .mycontainer {
    display: flex;
    padding: 0; }

  .mycontainer__mobile-header {
    display: none; }

  .mycontainer__sidebar {
    display: block;
    width: 30%;
    min-width: 320px;
    box-sizing: border-box;
    padding: 40px;
    background-color: $light-bg-color;
    position: fixed; }

  .mycontainer__body {
    display: block;
    width: 70%;
    min-width: 580px;
    box-szing: border-box;
    padding: 40px;
    padding-left: 30%;
    position: fixed;
    height: 100%; }

  .mycontainer__body--white {
    background-color: white; }

  .scrolling-container {
    overflow: scroll;
    height: 100%; }

  .myheader-container {
    padding-top: 0;
    padding-left: 60px;
    padding-bottom: 0;
    text-align: center; }

  .mydiv {
    background-color: #f4f4f4; } }
