.mycard {
  box-shadow: 0 0 5px #ccc;
  height: 442px;
  background-color: rgba(white, 0.7); }

.mycard__image {
  display: block;
  position: relative;

  img {
    display: block; }

  &:before, &:after {
    position: absolute;
    z-index: 1;
    content: "";
    opacity: 0;
    transition: opacity 0.2s ease-in-out; }

  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.7); }

  &:after {
    width: 80px;
    height: 80px;
    background: url('https://cdnjs.cloudflare.com/ajax/libs/foundicons/3.0.0/svgs/fi-magnifying-glass.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px; }

  &:hover {
    &:before {
      opacity: 1; }

    &:after {
      opacity: .5; } } }

.mycard__caption {
  background-color: white;
  padding: 20px; }

.mycard__title {
  margin-bottom: 10px;

  a {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    color: $dark-body-color;

    &:hover {
      border-bottom: dotted 1px #9055a2; } } }

.mycard__description {
  color: $light-body-color;
  font-size: 14px;
  line-height: 1.4; }
